<template>
	<PaddingContainer
		class="blog-posts-carousel-listing-wrapper"
		:padding="padding"
		component="section"
		:class="{
			'blog-posts-carousel-section-padding': !padding
		}"
	>
		<h2 v-if="title" class="blog-posts-carousel-heading">
			{{ title }}
		</h2>

		<LoadingIcon v-if="isFetchingBlogPosts" class="loading" />

		<div v-else class="carousel-listing">
			<div v-if="blogPosts.length">
				<div
					:key="carouselId"
					ref="carouselContainer"
					:class="carouselContainerClass"
					class="carousel-container"
				>
					<div :class="carouselWrapperClass" class="carousel-wrapper">
						<BlogPostCard
							v-for="blogPost in blogPosts"
							:key="blogPost.id"
							:class="carouselItemClass"
							:blog-post="blogPost"
						/>
					</div>
				</div>

				<div class="carousel-section-navigation">
					<div class="carousel-navigation">
						<button
							aria-label="Ir para o post anterior"
							@click="carousel.goToPreviousImage"
						>
							<ChevronLeft />
						</button>
						<button
							aria-label="Ir para o próximo post"
							@click="carousel.goToNextImage"
						>
							<ChevronRight />
						</button>
					</div>
					<ButtonBlock
						text="Ver mais posts"
						variant="square"
						is-outlined
						url="/blog"
						class="view-all-button"
					/>
				</div>
			</div>
			<ErrorMessage
				v-else
				title="Nenhum post foi encontrado"
				:description="errorMessage"
			/>
		</div>
	</PaddingContainer>
</template>

<script setup lang="ts">
import type { BlogPostsCarouselSection } from '@SHARED/core/entities/sections/BlogPostsCarouselSection';
import type { BlogPost } from '@SHARED/core/entities/BlogPost';
import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';

import { useId } from '@SHARED/composables/useId';
import { getCSSColorVar } from '@SHARED/utils/style';

import { useCarousel } from '@SHARED/composables/useCarousel';

import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import ErrorMessage from '@SHARED/components/molecules/ErrorMessage.vue';
import BlogPostCard from '@/components/molecules/BlogPostCard.vue';

import LoadingIcon from '~icons/mdi/loading';
import ChevronLeft from '~icons/mdi/chevron-left';
import ChevronRight from '~icons/mdi/chevron-right';

defineOptions({ name: 'BlogPostsCarouselSection' });

type BlogPostsCarouselSectionProps = BlogPostsCarouselSection['config'] & {
	blogPosts?: BlogPost[] | null;
	blogPostSlug?: string | null;
};

const props = withDefaults(defineProps<BlogPostsCarouselSectionProps>(), {
	blogPosts: null,
	postsLimit: 5,
	backgroundColor: 'offwhite',
	blogPostSlug: null
});

const styles = useState<WebsiteStyles>('styles');

const domain = useState<string>('domain');

const route = useRoute();

const blogPostsRepository = inject(BLOG_POSTS_REPOSITORY)!;

const {
	carouselContainer,
	carousel,
	carouselContainerClass,
	carouselWrapperClass,
	carouselItemClass
} = useCarousel({
	spaceBetween: 10,
	breakpoints: {
		768: { slidesPerView: 2 },
		1440: { slidesPerView: 3 }
	},
	shouldWatchCarouselContainerElement: true
});

const errorMessage = ref<string | null>(null);

const backgroundColor = computed<string>(() =>
	getCSSColorVar(props.backgroundColor || styles.value.appearance.background)
);

const { data: blogPosts, pending: isFetchingBlogPosts } = useAsyncData<
	BlogPost[]
>(
	`${domain.value}:blog-posts-carousel:${props.blogPostSlug || route.path}`,
	async () => {
		try {
			if (props.blogPosts) return props.blogPosts;

			const [blogPostsResponse, blogPostsRequestError] =
				await blogPostsRepository.getCompanyBlogPosts({
					domain: domain.value,
					maxQuantity: props.postsLimit || 5,
					offset: 0
				});

			if (blogPostsRequestError || !blogPostsResponse) {
				const error = blogPostsRequestError?.originalErrorObject as any;
				// <!-- TODO: improve error handling -->
				// <!-- TODO: adicionar mensagens de erro para filtros -->
				// eslint-disable-next-line no-console
				console.error(blogPostsRequestError);

				if (error?.response.status === 404) {
					errorMessage.value = 'No momento, não temos nenhum post disponível.';
				}

				return [];
			}

			return blogPostsResponse.blogPosts;
		} catch {
			return [];
		}
	},
	{ default: () => [] }
);

const carouselId = ref(useId('blog-posts-carousel'));

watch(blogPosts, () => {
	carouselId.value = useId('blog-posts-carousel');
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins/breakpoint.scss';

.blog-posts-carousel-section-padding {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.blog-posts-carousel-listing-wrapper {
	background-color: v-bind(backgroundColor);
	display: flex;
	flex-direction: column;

	.blog-posts-carousel-heading {
		font-size: 1.875rem;
		width: 100%;
		text-align: center;
		font-family: var(--heading-font);

		margin-bottom: 1.5rem;

		@include screen-up(md) {
			font-size: 2.25rem;
		}
	}
}

.carousel-listing {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	width: 100%;
}

.carousel-wrapper {
	width: 100%;
	padding: 1rem 0;
}

.carousel-container {
	height: 100%;
}

.carousel-section-navigation {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.view-all-button {
		font-weight: 500;
	}
}

.carousel-navigation {
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;

	button {
		border: 2px solid var(--black);
		border-radius: 5rem;
		padding: 0.25rem;
		color: var(--black);
		transition: all 0.2s ease-in-out;
		background-color: transparent;

		&:hover {
			color: var(--white);
			background-color: var(--black);
		}

		@include screen-up(sm) {
			padding: 0.5rem;
		}
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
	}
}
</style>
